import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'

const NotFoundPage = () => (
	<Layout>
			<SEO title={`Not found`} />
			<div className="center">
				<h1>NOT FOUND</h1>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
				<Link to={`/`} >
					Check out my gallery
				</Link>
			</div>
	</Layout>
)

export default NotFoundPage
